import * as React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import Layout from '../layout';
import Seo from '../seo';
import padding from '../../util/padding';
import PostBlock from '../post-block';

export type Props = {
  pageContext: {
    limit: number;
    skip: number;
    pageCount: number;
    currentPage: number;
  };
  data: {
    allMdx: {
      edges: Array<{
        node: {
          id: string;
          timeToRead: string;
          frontmatter: {
            slug: string;
            title: string;
            date: string;
            tags: Array<string>;
            hero: {
              darken?: string;
              author?: string;
              unsplashUser?: string;
              file: {
                childImageSharp: {
                  fluid: {
                    src: string;
                    srcSet: string;
                  };
                };
              };
            };
          };
          body: string;
          excerpt: string;
        };
      }>;
    };
  };
};

const PostsPage = ({ data, pageContext }: Props) => {
  const desc = `Blog Page ${pageContext.currentPage}`;
  const posts = data.allMdx.edges;

  return (
    <Layout>
      <Seo title={desc} description={desc} />
      <div
        css={css`
          ${padding}
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        {posts.map(({ node: post }) => {
          return (
            <PostBlock
              key={post.id}
              slug={post.frontmatter.slug}
              title={post.frontmatter.title}
              date={post.frontmatter.date}
              tags={post.frontmatter.tags}
              excerpt={post.excerpt}
              image={post.frontmatter.hero.file.childImageSharp.fluid}
            />
          );
        })}
      </div>
    </Layout>
  );
};

export default PostsPage;

export const postQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allMdx(
      skip: $skip
      limit: $limit
      sort: { order: DESC, fields: frontmatter___date }
      filter: { fields: { type: { eq: "posts" } } }
    ) {
      edges {
        node {
          id
          excerpt
          timeToRead
          frontmatter {
            slug
            title
            date(formatString: "MMMM DD, YYYY")
            tags
            hero {
              file {
                childImageSharp {
                  fluid(
                    maxWidth: 720
                    maxHeight: 400
                    fit: COVER
                    jpegProgressive: true
                  ) {
                    src
                    srcSet
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
